.notes-editor-loading {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px; }

.notes-editor-container {
  width: 800px;
  margin: auto;
  margin-top: 60px; }
  .notes-editor-container .notes-editor-main {
    width: 600px;
    margin: auto;
    background-color: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 2px 2px rgba(225, 232, 237, 0.8); }
  .notes-editor-container .notes-editor-side {
    float: right;
    width: 80px;
    padding-top: 250px; }
    .notes-editor-container .notes-editor-side .notes-editor-save-button {
      position: fixed; }
      .notes-editor-container .notes-editor-side .notes-editor-save-button::before {
        content: '';
        height: 12px;
        width: 12px;
        background-color: #337ab7;
        border-radius: 50%;
        position: absolute;
        left: -6px;
        border: 2px solid #f5f8fa;
        opacity: 0;
        transition: opacity ease-in-out 0.15s; }
      .notes-editor-container .notes-editor-side .notes-editor-save-button:focus {
        outline: 0; }
      .notes-editor-container .notes-editor-side .notes-editor-save-button.notes-editor-unsaved::before {
        opacity: 1; }

.notes-show-header {
  padding: 10px 0 35px 0;
  font-family: 'WeblySleek UI', Helvetica, sans-serif; }
  .notes-show-header h1 {
    font-weight: 600;
    font-size: 28px;
    margin-bottom: 0; }
  .notes-show-header h2 {
    font-size: 20px;
    margin-top: 10px;
    color: #999;
    font-weight: 400; }

.notes-show-header {
  padding-bottom: 10px; }
  .notes-show-header .pt-editable-text.pt-multiline .pt-editable-content {
    overflow: visible; }

.notes-show-content {
  font-family: 'Lyon Text Web', Georgia, sans-serif;
  font-size: 15px;
  line-height: 24px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000; }
  .notes-show-content h2 {
    font-family: 'WeblySleek UI', Helvetica, sans-serif;
    font-size: 23px;
    margin-top: 50px;
    margin-bottom: 15px;
    padding-bottom: 5px;
    border-bottom: 1px solid #e9e9e9;
    font-weight: bold; }
    .notes-show-content h2:first-child {
      margin-top: 0; }
  .notes-show-content h3 {
    font-family: 'WeblySleek UI', Helvetica, sans-serif;
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 15px;
    padding-bottom: 5px;
    border-bottom: 1px solid #eee; }
  .notes-show-content h2 a, .notes-show-content h3 a {
    color: inherit; }
    .notes-show-content h2 a:hover, .notes-show-content h3 a:hover {
      border: none; }
  .notes-show-content blockquote {
    border-left: none;
    font-style: italic;
    color: #777;
    border-left: 2px solid #e4e4e4; }
  .notes-show-content cite {
    color: #BBB;
    font-size: 0.8em;
    text-transform: uppercase;
    transition: color 0.3s; }
    .notes-show-content cite:hover {
      color: #999; }
  .notes-show-content crossterm {
    background-color: #FFFDC9;
    padding: 0 1px; }
    .notes-show-content crossterm::before {
      content: '@';
      color: #DDD; }
  .notes-show-content p {
    margin-bottom: 15px; }
  .notes-show-content ul li, .notes-show-content ol li {
    margin-bottom: 5px; }
    .notes-show-content ul li ul, .notes-show-content ul li ol, .notes-show-content ol li ul, .notes-show-content ol li ol {
      margin-top: 5px; }

.notes-show-content h1 .public-DraftStyleDefault-block, .notes-show-content h2 .public-DraftStyleDefault-block, .notes-show-content h3 .public-DraftStyleDefault-block, .notes-show-content h4 .public-DraftStyleDefault-block {
  margin: 0; }

.notes-editor-editor-toolbar.rdw-editor-toolbar {
  padding: 0;
  border: 0;
  font-family: -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Open Sans", "Helvetica Neue", "Icons16", sans-serif; }
  .notes-editor-editor-toolbar.rdw-editor-toolbar .rdw-option-wrapper {
    padding: 2px;
    min-width: 20px;
    border: 0;
    margin: 0 2px; }

.rdw-link-decorator-icon {
  display: none; }

.notes-editor-meta {
  margin-bottom: 40px; }
  .notes-editor-meta .notes-editor-meta-field {
    display: inline-block;
    margin-right: 20px; }
    .notes-editor-meta .notes-editor-meta-field .pt-switch {
      position: relative;
      top: 5px;
      margin-left: 5px; }
    .notes-editor-meta .notes-editor-meta-field .pt-popover-wrapper {
      vertical-align: middle; }
      .notes-editor-meta .notes-editor-meta-field .pt-popover-wrapper input.pt-input {
        width: 100px; }

.notes-editor-form-slug {
  font-family: 'Input Mono', Menlo, Consolas, monospace;
  font-size: 13px; }
