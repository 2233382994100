.journal-editor-root {
  display: flex;
  height: 100%;
  min-width: 100%;
  position: absolute;
  flex-direction: row; }

.journal-editor-loading {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center; }

.journal-editor-form {
  flex: 1;
  margin: 20px 10px 20px 20px; }
  .journal-editor-form .journal-editor-form-inner {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%; }
  .journal-editor-form input[type="text"] {
    width: 100%; }
  .journal-editor-form .journal-editor-form-top {
    flex: initial; }
    .journal-editor-form .journal-editor-form-top .journal-editor-form-slug {
      margin-bottom: 5px;
      font-family: 'Input Mono', Menlo, Consolas, monospace;
      font-size: 13px; }
    .journal-editor-form .journal-editor-form-top .pt-form-group {
      margin-bottom: 5px; }
    .journal-editor-form .journal-editor-form-top .journal-editor-form-meta .journal-editor-form-meta-field {
      width: calc(33.3% - 5px);
      display: inline-block;
      margin-right: 5px; }
      .journal-editor-form .journal-editor-form-top .journal-editor-form-meta .journal-editor-form-meta-field:last-child {
        width: 33.3%;
        margin-right: 0; }
    .journal-editor-form .journal-editor-form-top .pt-tab:focus {
      outline: 0; }
  .journal-editor-form .journal-editor-form-content {
    flex: auto;
    position: relative; }
    .journal-editor-form .journal-editor-form-content textarea {
      height: 100%;
      position: absolute;
      font-family: 'Input Mono', Menlo, Consolas, monospace; }
  .journal-editor-form .journal-editor-form-actions {
    flex: initial;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .journal-editor-form .journal-editor-form-actions .journal-editor-form-actions-main {
      display: flex;
      align-items: center; }

.journal-editor-preview {
  flex: 1;
  margin: 20px 20px 20px 10px; }
  .journal-editor-preview .pt-card {
    height: 100%;
    overflow-y: scroll;
    overflow-x: auto; }

.journal {
  height: 100%;
  height: 100%;
  min-width: 100%; }

.journal-normal-style .journal-normal-head {
  padding-top: 40px;
  padding-bottom: 50px;
  text-align: center; }

.journal-normal-style h1 {
  color: #3A81B5;
  line-height: 46px;
  font-family: 'Lyon Display Web', Georgia, sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
  -moz-font-feature-settings: "lnum" 1;
  -ms-font-feature-settings: "lnum" 1;
  -o-font-feature-settings: "lnum" 1;
  -webkit-font-feature-settings: "lnum" 1;
  font-feature-settings: "lnum" 1; }

.journal-normal-style h2 {
  color: #848484;
  font-size: 20px;
  font-weight: 300; }

.journal-normal-style .journal-normal-body {
  font-family: 'Lyon Text Web', Georgia, sans-serif;
  font-size: 17px;
  line-height: 30px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #111; }
  .journal-normal-style .journal-normal-body p {
    margin-bottom: 25px; }
  .journal-normal-style .journal-normal-body h3 {
    margin-top: 60px;
    margin-bottom: 25px;
    font-family: 'WeblySleek UI', Helvetica, sans-serif;
    letter-spacing: -1px;
    font-weight: 600; }
  .journal-normal-style .journal-normal-body ul li, .journal-normal-style .journal-normal-body ol li {
    margin-bottom: 20px; }
