.auth-container {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: absolute; }
  .auth-container .pt-card {
    width: 350px; }
  .auth-container .auth-header {
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 8px;
    font-size: 20px;
    color: #293742;
    margin-bottom: 20px; }
    .auth-container .auth-header span {
      color: #8A9BA8; }
